<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-snackbar
                        :color="snackbar.color"
                        v-model="snackbar.show"
                        right
                      >
                        {{ snackbar.message }}
                      </v-snackbar>
                      <v-card>
                        <v-toolbar color="indigo darken-4" dark>
                          <v-toolbar-title>Welcome Lecturer</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <div style="margin-top: 20px"></div>
                        <div v-if="this.listSession.length > 0">
                          <div v-for="(itemGen, i) in listGroupResult" :key="i">
                            <div class="grey lighten-3">
                              <v-divider></v-divider>
                              <v-card-title class="font-weight-black">
                                {{ i }}
                              </v-card-title>
                              <v-divider></v-divider>
                            </div>
                            <v-row class="ma-2">
                              <v-col
                                sm="4"
                                cols="12"
                                v-for="(item, j) in itemGen"
                                :key="j"
                              >
                                <v-card
                                  max-width="450"
                                  class="mx-auto"
                                  color="teal darken-1"
                                  dark
                                  @click="
                                    $router.push({
                                      name: 'Score',
                                      params: {
                                        id: item.id,
                                        name: item.name,
                                      },
                                    })
                                  "
                                >
                                  <v-card-title
                                    class="text-center"
                                    style="font-weight: bold; font-size: 20px"
                                  >
                                    {{ item.name }}
                                  </v-card-title>

                                  <router-link
                                    style="text-decoration: none"
                                    :to="
                                      'lecturer/scores/points/' +
                                      item.id +
                                      '&' +
                                      item.name
                                    "
                                    tag="button"
                                  >
                                  </router-link>
                                  <v-card-actions
                                    style="background-color: #ffa726"
                                  >
                                    {{ item.major_name }} -
                                    {{ item.magor_gen }} -
                                    {{ item.session_name }}
                                  </v-card-actions>
                                </v-card>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                        <v-row
                          v-else
                          align="center"
                          justify="center"
                          class="mb-5"
                        >
                          <div>
                            <v-img
                              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"
                              class="center"
                            ></v-img>
                            <div class="text-h6 text-center">No content</div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import { getSubjectByLect } from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      isLoading: false,
      myLoading: false,
      token: store.getters.getToken,
      listSession: [],
      listGroupResult: [],
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
    };
  },

  methods: {
    async loadScore() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        let loggedUser = store.getters.getLoggedUser;
        let lectEmail = loggedUser.payload.user.email;
        const APIResponse = await getSubjectByLect(lectEmail, this.token);
        let data = APIResponse.payload;
        this.listSession = data.sort(function (a, b) {
          var c = new Date(a.magor_gen);
          var d = new Date(b.magor_gen);
          return d - c;
        });
        this.listGroupResult = this.groupBy(this.listSession, "magor_gen");

        window.console.log(this.listGroupResult);
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
  },
  computed: {},
  mounted() {
    this.loadScore();
  },
  components: {
    Loading,
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
}
</style>
