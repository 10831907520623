var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('div',{staticStyle:{"background-color":"#fff","padding":"20px 20px","border-radius":"10px"}},[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('v-row',[[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"right":""},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Welcome Lecturer")]),_c('v-spacer')],1),_c('div',{staticStyle:{"margin-top":"20px"}}),(this.listSession.length > 0)?_c('div',_vm._l((_vm.listGroupResult),function(itemGen,i){return _c('div',{key:i},[_c('div',{staticClass:"grey lighten-3"},[_c('v-divider'),_c('v-card-title',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(i)+" ")]),_c('v-divider')],1),_c('v-row',{staticClass:"ma-2"},_vm._l((itemGen),function(item,j){return _c('v-col',{key:j,attrs:{"sm":"4","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"450","color":"teal darken-1","dark":""},on:{"click":function($event){return _vm.$router.push({
                                    name: 'Score',
                                    params: {
                                      id: item.id,
                                      name: item.name,
                                    },
                                  })}}},[_c('v-card-title',{staticClass:"text-center",staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'lecturer/scores/points/' +
                                    item.id +
                                    '&' +
                                    item.name,"tag":"button"}}),_c('v-card-actions',{staticStyle:{"background-color":"#ffa726"}},[_vm._v(" "+_vm._s(item.major_name)+" - "+_vm._s(item.magor_gen)+" - "+_vm._s(item.session_name)+" ")])],1)],1)}),1)],1)}),0):_c('v-row',{staticClass:"mb-5",attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-img',{staticClass:"center",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fempty.png?alt=media&token=15a9dbf8-dca3-4df9-981d-f621d354e4ae"}}),_c('div',{staticClass:"text-h6 text-center"},[_vm._v("No content")])],1)])],1)],1)],1)]],2)],1)],1)])],1)],1),_c('div',[_c('div',{staticClass:"loading"},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":_vm.fullPage,"opacity":0.9,"background-color":"#dedede","width":40,"height":40},on:{"update:active":function($event){_vm.isLoading=$event}}},[(_vm.myLoading)?_c('div',[_c('img',{attrs:{"width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"}})]):_vm._e()])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }